import { Options } from "ahooks/lib/useRequest/src/types";

/**
 * @Author wangyubo
 * @Date 2023/12/21
 * @Path src\hooks\useOptions.ts
 * @IDE useOptions.ts
 * @Desc 放置一些获取 通用数据的hook 以及处理他们的返回参数
 **/
import {
  getNewsCategory,
  GetNewsCategoryParams,
  GetNewsCategoryRes,
} from "@/service/news";
import { useState } from "react";
import { useRequest } from "ahooks";
import { getDictListByName } from "@/service/dict";
import { useLocation } from "react-router-dom";
import { tree2list } from "@/utils/file";

/**
 * @desc 用于ant-select组件的定义
 */
export interface AntOptions {
  label: string;
  value: any;
}

/**
 * @desc 把数据转成AntOptions 格式的数组
 * @param arr 原数组
 * @param name 名字字段
 * @param value 值字段
 */
export function formatOptions<T>(
  arr: T[],
  name: string,
  value: any
): AntOptions[] {
  return arr.map((item: any) => {
    return {
      label: item[name],
      value: item[value],
    };
  });
}

/** 把数据转成 ant-tree 可以使用的格式 */
function formatTreeData(arr: any[]): any[] {
  if (!arr) return [];
  return arr.map((item) => {
    const { children, nodeName, id } = item;
    if (children && children.length > 0) {
      return {
        title: nodeName,
        value: id,
        children: formatTreeData(children),
      };
    }
    return {
      title: nodeName,
      value: id,
    };
  });
}

// <editor-fold desc="新闻分类" defaultstate="collapsed">
export function useGetNewsCategory(
  options: Options<any, any>,
  params: Partial<GetNewsCategoryParams>,
  callback?: Function
) {
  /** 元数据 */
  const [treeData, setTreeData] = useState<GetNewsCategoryRes[]>([]);
  /** 列表数据 */
  const [listData, setListData] = useState<AntOptions[]>([]);

  const { run, loading } = useRequest(
    (data = params) => getNewsCategory(data),
    {
      ...options,
      onSuccess: (res) => {
        console.log("res:", res);
        setTreeData(res);
        setListData(
          res?.map((item) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
        callback?.();
      },
    }
  );

  return { treeData, listData };
}

// </editor-fold>

/**
 * @desc 获取字典数据
 * @param name
 * @param options
 */
export function useGetDict(name: string, options?: Options<any, any>) {
  /** 元数据 */
  const [list, setList] = useState<any[]>([]);
  /** 转化后的数据 */
  const [formatList, setFormatList] = useState<AntOptions[]>([]);
  useRequest(() => getDictListByName(name), {
    ...options,
    ready: true,
    onSuccess: (res) => {
      setList(res);
      setFormatList(formatOptions(res, "title", "value"));
    },
  });

  return { list, formatList };
}

/** 获取当前路由 的菜单名  */
export function useGetNavName() {
  const location = useLocation();
  const navList: any[] = JSON.parse(sessionStorage.getItem("NAV_LIST") || "[]");
  const list = tree2list(navList);
  const item = list?.find(
    (item) => item?.detail?.data?.url === location?.pathname
  );
  return { item };
}
