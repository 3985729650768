import { Avatar, Dropdown, MenuProps, Modal, Tag } from "antd";
import { ExclamationCircleTwoTone, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { logout } from "@/service/login";
import storeService from "@/service/store.service";

import styles from "./index.module.less";
import { PartnerTypeOptions } from "@/views/backend/admissionCert/AddCert";

export default function CurrentUser({ preview = false, isSystem = false }) {
  const navigate = useNavigate();
  const userInfo = storeService.getBaseInfo();

  function handleMenuClick({ key }: { key: string }) {
    switch (key) {
      case "info":
        // navigate('/backend/userInfo');
        break;
      case "hall":
        navigate("/backend/dataDemand");
        break;
      case "service":
        navigate("/backend/ServiceSupport/ProblemFeedback");
        break;
      case "active":
        if (process.env.REACT_APP_HIDE_MATCH === "true") {
          navigate("/backend/activitiesManagement/zoneActivity");
        } else {
          navigate("/backend/activitiesManagement/innovativeMatch");
        }
        break;
      case "workplat":
        navigate("/backend/workplat");
        break;
      case "logout":
        Modal.confirm({
          title: "确定退出登录?",
          onOk() {
            logout().then();
            sessionStorage.clear();
            navigate("/login");
          },
        });
        break;
    }
  }

  const items: MenuProps["items"] = [
    {
      key: "info",
      label: (
        <div
          style={{ whiteSpace: "nowrap", color: "#002855", paddingTop: "0.75rem" }}
        >
          <div className={styles.name_line}>
            <span>用户账号 &nbsp;</span>
            <div className={styles.name_tag}>
              {userInfo?.isSub ? "子账号" : "主账号"}
            </div>
          </div>
          <div
            className={styles.account_line}
            onClick={() => navigate("/backend/userInfo")}
          >
            {userInfo?.username}
          </div>
          {/* <div
            className={styles.no_cert}
            r-if={userInfo?.customerAuths?.length === 1}
          >
            <Tag color={"#369afe"}>
              {
                PartnerTypeOptions?.find(
                  (pItem) =>
                    pItem.value === userInfo?.customerAuths?.[0]?.partnerType
                )?.label
              }
            </Tag>
          </div> */}
          <div className={styles.no_cert}>
            {userInfo?.customerAuths?.map((item) => {
              return (
                <Tag color={"#369afe"} key={item.id}>
                  {
                    PartnerTypeOptions?.find(
                      (pItem) => pItem.value === item.partnerType
                    )?.label
                  }
                </Tag>
              );
            })}
          </div>
          <div
            className={styles.cert_line}
            r-if={
              !userInfo?.customerAuths || userInfo?.customerAuths?.length === 0
            }
          >
            <span className={styles.no_cert}>
              <ExclamationCircleTwoTone
                twoToneColor={"#FF9408"}
              ></ExclamationCircleTwoTone>
              未生态伙伴准入认证
            </span>
            <span
              className={styles.to_cert}
              onClick={() => navigate("/backend/admissionCert")}
            >
              {"去认证 >"}
            </span>
          </div>
        </div>
      ),
    },
    { type: "divider" },
    /*{
      key: 'hall',
      label: '数聚大厅',
    },
    {type: 'divider'},
    {
      key: 'service',
      label: '服务支持',
    },
    {type: 'divider'},
    {
      key: 'active',
      label: '活动管理',
    },*/
    {
      key: "workplat",
      label: "企业中心",
    },
    { type: "divider" },
    {
      key: "logout",
      label: (
        <span className={styles.loginOutWrapper}>
          <span>退出登录 </span>
          <span className={styles.loginOut}></span>
        </span>
      ),
    },
  ];
  return (
    <Dropdown menu={{ items, onClick: handleMenuClick }}>
      <div className={styles.user}>
        {userInfo?.avatar ? (
          <Avatar src={userInfo?.avatar} />
        ) : (
          <Avatar icon={<UserOutlined />} />
        )}
        <span>{userInfo?.enterpriseName}</span>
      </div>
    </Dropdown>
  );
}
