import { get, post } from "@/utils/request";

const commonUrl = process.env.REACT_APP_URL;

/**
 * 服务专区分页查询
 * @param data 参数
 */
export function getServiceAreaList(data) {
  return post(`${commonUrl}/yy-api-sector/sector/page`, { data });
}

/**
 * 服务专区热词
 * @param data 参数
 */
export function getServiceAreaHotWord() {
  return get(`${commonUrl}/yy-api-sector/sector/hotSearch`);
}

/**
 * 服务专区查询详情
 * @param id id
 */
export function getServiceAreaItemDetail(data) {
  return post(`${commonUrl}/yy-api-sector/sector/page/detail`, {
    data,
  });
}

/**
 * 服务专区查询更多新闻
 * @param id id
 */
export function getServiceAreaMoreNews(data) {
  return post(`${commonUrl}/yy-api-sector/sector/info/page`, {
    data,
  });
}


/**
 * 服务专区分页查询 更多列表
 * @param data 参数
 */
export function getServiceAreaItem(data) {
  return post(`${commonUrl}/yy-api-sector/sector/product/page`, { data });
}




