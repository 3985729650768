import { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { Button, Tabs } from "antd";
import { useNavigate } from "react-router-dom";

import styles from "./index.module.less";
import {
  getHomeApply,
  getHotDataSet,
  getHotModelProduct,
} from "@/service/home";
import { useRequest } from "ahooks";
import { apiProductHotHome, apiProductHotHomeOld } from "@/service/products";
import { getWeight, sortNestedArrayByWeight } from "@/views/layout/commonMenus";
import { getServiceAreaList } from "@/service/serviceArea";

type MenuItem = Required<MenuProps>["items"][number];

const getIcons = (name: string) => {
  return require(`@/assets/dataProducts/${name}.png`);
};

const ProductTab = ({
  selectedKey,
  routes,
  navList,
}: {
  selectedKey: string;
  navList: any[];
  routes: MenuItem[];
}) => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<string>("");
  const [apidata, setApiData] = useState<any[]>([]);
  const [applydata, setApplyData] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [dataSet, setDataSet] = useState<any[]>([]); // 做鼠标悬停后效果
  const [modelApi, setModelApi] = useState<any[]>([]); // 做鼠标悬停后效果
  const [serviceArea, setServiceArea] = useState<any[]>([]); // 做鼠标悬停后效果
  /** 给routes 加上 权重等字段 */
  const [resultRoutes, setResultRoutes] = useState<any[]>(routes);

  useEffect(() => {
    if (routes && navList) {
      const tempData = routes.map((item: any) => {
        return {
          ...item,
          label: item?.label || "",
          weight: getWeight(item.key as string, navList),
        };
      });
      setResultRoutes(sortNestedArrayByWeight(tempData));
    }
  }, [routes, navList]);

  /** 自动查询 api产品 热门数据   */
  // const {} = useRequest(apiProductHotHome, {
  //   defaultParams: [false],
  //   onSuccess: (res) => {
  //     setApiData(res?.map(item => ({
  //       ...item,
  //       dataName: item?.productName,
  //       desc: item?.versionRemark
  //     })))
  //   }
  // })

  /**  自动查询 api产品 热门数据 旧数据  */
  const {} = useRequest(apiProductHotHomeOld, {
    onSuccess: (res) => {
      setApiData(res)
    }
  })

  // 热门数据产品
  useEffect(() => {
    /* getHomeApi()
       .then((res) => {
         setApiData(
           res?.map?.((ele) => ({
             ...ele,
             dataName: ele.dataName || ele.name,
             scene: ele.scene || ele.applyScene,
           })) || []
         );
       })
       .catch((e) => e);*/
    getHomeApply()
      .then((res) => {
        setApplyData(
          res?.map?.((ele) => ({
            ...ele,
            dataName: ele.dataName || ele.name,
            scene: ele.scene || ele.applyScene,
          })) || []
        );
      })
      .catch((e) => e);

    // 数据集热门
    getHotDataSet()
      .then((res) => {
        setDataSet(
          res?.map?.((ele) => ({
            ...ele,
            dataName: ele.datasetName || ele.name,
            desc: ele.datasetDescription,
            scene: ele.scene || ele.applyScene,
          })) || []
        );
      })
      .catch((e) => e);

    // 模型产品热门
    getHotModelProduct()
      .then((res) => {
        setModelApi(
          res?.records?.map?.((ele: any) => ({
            ...ele,
            dataName: ele.datasetName || ele.name,
            desc: ele.datasetDescription || ele.desc,
            scene: ele.scene || ele.applyScene,
          })) || []
        );
      })
      .catch((e) => e);
    // 产品服务专区
    getServiceAreaList({
      pageNo: 1,
      pageSize: 9,
      release: 1,
    })
      .then((res) => {
        setServiceArea(
          res?.records?.map?.((ele: any) => ({
            ...ele,
            dataName: ele.name,
            desc: ele.remark,
            scene: ele.scene || ele.applyScene,
          })) || []
        );
      })
      .catch((e) => e);
  }, []);
  useEffect(() => {
    if (activeKey === "/products/api") {
      setData(apidata);
    } else if (activeKey === "/products/apply") {
      setData(applydata);
    } else if (activeKey === "/products/dataSet") {
      setData(dataSet);
    } else if (activeKey === "/products/modelApi") {
      setData(modelApi);
    } else if (activeKey === "/products/serviceArea") {
      setData(serviceArea);
    }
  }, [activeKey, apidata, applydata, dataSet, serviceArea]);

  useEffect(() => {
    const keys = resultRoutes?.map((ele) => ele?.key);
    let matchedPath: any = "";

    for (const path of keys) {
      if (selectedKey.includes(path as string)) {
        matchedPath = path;
        break;
      }
    }
    if (matchedPath) {
      setActiveKey(matchedPath);
    } else {
      /** 未选中时 默认设置选中Api  */
      setActiveKey("/products/api");
    }
    // keys?.forEach((item: any) => {
    //   if (selectedKey?.includes(item)) {
    //     console.log(item, 'includs方法')
    //     setActiveKey(item)
    //   } else {
    //   }
    // })
  }, [selectedKey, resultRoutes]);

  const TabContent = ({ path }: { path: string }) => (
    <div className={styles.tabContent}>
      {path === "/products/serviceArea" ? null : (
        <div className={styles.title}>
          <div>
            <span>热门推荐</span>
            <img src={getIcons("hot_tab")} alt="热门" />
          </div>
          <Button
            className={styles.btn}
            shape="round"
            size="small"
            onClick={() => navigate(path)}
          >
            查看全部
          </Button>
        </div>
      )}

      <div
        className={styles.listWrapper}
        style={{ marginTop: path === "/products/serviceArea" ? 14 : "" }}
      >
        {data?.map((ele) => (
          <div
            className={styles.content}
            key={ele.id}
            onClick={() =>
              navigate(`${path}/detail/${ele.id}`, {
                state: {
                  name: ele.dataName || ele.name,
                  authMode: ele?.authMode,
                },
              })
            }
          >
            <div className={styles.name}>
              <img src={getIcons("tab_icon2")} alt="产品" />
              <div className={styles.span} title={ele.dataName}>
                {ele.dataName}
              </div>
            </div>
            <div className={styles.intro} title={ele.desc}>
              {ele.desc}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Tabs
      className={styles.tabWrapper}
      activeKey={activeKey}
      onTabClick={(val) => {
        navigate(val);
      }}
      tabPosition="left"
      items={
        resultRoutes?.map((route: any) => ({
          ...route,
          label: (
            <span
              onMouseEnter={() => {
                setActiveKey(route.key);
              }}
            >
              {route.label}
            </span>
          ),
          children: <TabContent path={route?.key as string} />,
        })) as any
      }
    />
  );
};
export default ProductTab;
