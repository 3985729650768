import {useEffect, useMemo, useRef, useState} from "react";
import {Button, Layout} from "antd";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import DcPage from "../../components/DcPage";
import CurrentUser from "../../components/CurrentUser";
import CommonMenus from "./commonMenus";
import {menuList, menuListArr} from "../../router/menu";
import MFooter from "./mFooter";
import {useMsgModal} from "@/components/MsgModal";
import messageIcon from "@/assets/home/message.png";
import storeService from "@/service/store.service";

import styles from "./index.module.less";
import {getGlobalNav, getGlobalSetting, MINIIO_PREVIEW_PATH} from "@/service/common";
import {useRequest} from "ahooks";
import {useReCertNoticeModal} from "@/components/reCertNoticeModal";
import {setFavicon} from "@/utils";

const {Header, Content, Footer} = Layout;

declare const window: {
  __POWERED_BY_QIANKUN__: boolean;
  isDev: boolean;
} & Window;

export default function DLayout() {
  const userInfo = storeService.getBaseInfo();
  const navigate = useNavigate();
  const location = useLocation();
  const headerRef = useRef<any>();
  const contentRef = useRef<any>();
  // const globalSetting = JSON.parse(sessionStorage.getItem("Global_SETTING") || '{}')
  // useFavicon(`${MINIIO_PREVIEW_PATH}?filePath=${globalSetting?.websiteIcon}`)
  /**  */
  const [globalSetting, setGlobalSetting] = useState(JSON.parse(sessionStorage.getItem("Global_SETTING") || '{}'));
  const [navList, setNavList] = useState(JSON.parse(sessionStorage.getItem("NAV_LIST") || '[]'));
  /** 获取重新认证提示弹窗  */
  const {jsx: reCertNoticeModal,} = useReCertNoticeModal()

  /** 获取 导航菜单 存到本地  */
  useRequest(getGlobalNav, {
    onSuccess: (res) => {
      sessionStorage.setItem("NAV_LIST", JSON.stringify(res))
      setNavList(res)
    }
  })
  /** 获取 全局设置 存到本地  */
  useRequest(getGlobalSetting, {
    onSuccess: (res) => {
      sessionStorage.setItem("Global_SETTING", JSON.stringify(res))
      setGlobalSetting(res)
      setFavicon(res?.websiteIcon);
      document.title= res?.websiteName
    }
  })

  // 额外需要banner的页面，不要4.375rem的 paddingtop
  const hasBannerPages = ["/products/serviceArea/detail", "/products/serviceArea/prodcts", "/products/serviceArea/news"];

  // 判断是否需要展示banner图
  const isBannerUrl = useMemo(() => {
    return hasBannerPages.some(url => location.pathname.includes(url)) || menuListArr.findIndex((i) => i.path === location.pathname) > -1;
  }, [location.pathname]);

  useEffect(() => {
    contentRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    function listenerFun(e: any) {
      if (contentRef.current.scrollTop <= 40) {
        headerRef.current.style.backgroundColor = "rgba(255, 255, 255, 0.3)";
      } else {
        headerRef.current.style.backgroundColor = "#fff";
      }
    }

    // 菜单中的页面才有大图
    if (menuListArr.findIndex((i) => i.path === location.pathname) > -1) {
      contentRef.current.addEventListener("scroll", listenerFun);
    } else {
      headerRef.current.style.backgroundColor = "#fff";
    }
    return () => {
      contentRef.current &&
      contentRef.current.removeEventListener("scroll", listenerFun);
    };
  }, [location.pathname]);

  // 留言咨询
  const {openModal, jsx} = useMsgModal();

  if (window.isDev) {
    //非乾坤环境
    return (
      <Layout className={styles.layout}>
        <Header ref={headerRef} className={styles.header}>
          <div className={styles.headerWrapper}>
            <div className={styles.logo}>
              <img src={`${MINIIO_PREVIEW_PATH}?filePath=${globalSetting?.platformLogo}`} alt=""/>
              <span
                onClick={() => {
                  navigate("/home");
                }}
              >
                {globalSetting?.platformName}
              </span>
            </div>
            <CommonMenus navList={navList}/>
            <div className={styles.user}>
              {userInfo ? (
                <CurrentUser/>
              ) : (
                <div className="user-opt">
                  <div onClick={() => navigate("/register")}>
                    注册
                  </div>
                  <span>/</span>
                  <div onClick={() => navigate("/login")}>
                    登录
                  </div>
                </div>
              )}
            </div>
          </div>
        </Header>
        <Content
          ref={contentRef}
          className={styles.content}
          style={{paddingTop: isBannerUrl ? 0 : 70}}
        >
          <div style={{flex: 1}}>
            <Outlet/>
          </div>
          <Footer className={styles.footer}>
            <MFooter/>
          </Footer>
        </Content>
        {menuList.findIndex(
          (i) =>
            !(i.children && i.children.length) && i.path === location.pathname
        ) > -1 && (
          <div className={styles.floatBtn} onClick={openModal}>
            <img src={messageIcon} alt=""/>
            <div>合作咨询</div>
          </div>
        )}
        {jsx}
        {reCertNoticeModal}
      </Layout>
    );
  }

  return (
    <DcPage title={""}>
      <Outlet/>
    </DcPage>
  );
}
