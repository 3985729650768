import { DcRoute } from "@/interface/DcRouter";
import { lazy } from "react";
import { treeForEach } from "@/utils";
import { PartnerTypeEnums } from "@/enum/user";

const NAV_LIST = JSON.parse(sessionStorage.getItem("NAV_LIST") || "[]");

export const menuList: DcRoute[] = [
  {
    name: "首页",
    path: "/home",
    meta: { title: "首页" },
    component: lazy(() => import("@/views/Home")),
    hidden: false,
    id: "1",
  },

  {
    name: "数据产品",
    path: "/products",
    meta: { title: "数据产品" },
    id: "2",
    hidden: false,
    children: [
      {
        name: "API产品",
        path: "/products/api",
        meta: { title: "API产品" },
        component: lazy(() => import("@/views/DataProducts/API")),
        id: "3",
        hidden: false,
      },
      {
        name: "应用产品",
        path: "/products/apply",
        meta: { title: "应用产品" },
        component: lazy(() => import("@/views/DataProducts/Apply")),
        hidden: false,
        id: "4",
      },
      {
        name: "应用产品详情",
        path: "/products/apply/detail/:id",
        meta: { title: "应用产品详情" },
        hidden: true,
        component: lazy(() => import("@/views/DataProducts/Apply/Detail")),
      },
      {
        name: "数据集产品",
        path: "/products/dataSet",
        meta: { title: "数据集产品" },
        component: lazy(() => import("@/views/DataProducts/DataSet")),
        hidden: false,
        id: "28",
      },
      {
        name: "数据集产品详情",
        path: "/products/dataSet/detail/:id",
        meta: { title: "数据集产品详情" },
        component: lazy(() => import("@/views/DataProducts/DataSet/Detail")),
        hidden: true,
        id: "7",
      },
      {
        name: "API产品详情",
        path: "/products/api/detail/:id",
        meta: { title: "API产品详情" },
        hidden: true,
        component: lazy(() => import("@/views/DataProducts/API/Detail")),
      },
      {
        name: "模型产品",
        path: "/products/modelApi",
        meta: { title: "模型产品" },
        component: lazy(() => import("@/views/DataProducts/ModelApi")),
        id: "10013",
        hidden: false,
      },
      {
        name: "模型产品详情",
        path: "/products/modelApi/detail/:id",
        meta: { title: "模型产品" },
        component: lazy(() => import("@/views/DataProducts/ModelApi/Detail")),
        hidden: true,
      },
      {
        name: "产品服务专区",
        path: "/products/serviceArea",
        meta: { title: "产品服务专区" },
        component: lazy(() => import("@/views/DataProducts/ServiceArea")),
        id: "10014",
        hidden: false,
      },
      {
        name: "产品服务专区",
        path: "/products/serviceArea/detail/:id",
        meta: { title: "产品服务专区" },
        component: lazy(
          () => import("@/views/DataProducts/ServiceArea/Detail")
        ),
        hidden: true,
      },
      {
        name: "更多新闻",
        path: "/products/serviceArea/news/:id",
        meta: { title: "更多新闻" },
        component: lazy(
          () => import("@/views/DataProducts/ServiceArea/Detail/newsMore")
        ),
        hidden: true,
      },
      {
        name: "更多产品",
        path: "/products/serviceArea/prodcts/:id",
        meta: { title: "更多产品" },
        component: lazy(
          () => import("@/views/DataProducts/ServiceArea/Detail/prodctMore")
        ),
        hidden: true,
      },
    ],
  },
  {
    name: "专业服务",
    path: "/services2",
    meta: { title: "专业服务" },
    hidden: false,
    id: "50",
    children: [
      {
        name: "数据专业服务",
        path: "/scenario",
        meta: { title: "数据专业服务" },
        component: lazy(() => import("@/views/DataScenario/DataSet")),
        hidden: false,
        id: "29",
      },
      {
        name: "数据资产入表",
        path: "/services2/table",
        meta: { title: "数据资产入表" },
        hidden: false,
        id: "8",
        component: lazy(() => import("@/views/DataProfessionalServices")),
      },
      {
        name: "人才培训",
        path: "/services2/train",
        meta: { title: "人才培训" },
        hidden: false,
        id: "9",
        component: lazy(() => import("@/views/DataProfessionalServices")),
      },
      {
        name: "数据标注平台",
        path: "/",
        meta: { title: "数据标注平台" },
        hidden: false,
        id: "10011",
        component: lazy(() => import("@/views/DataProfessionalServices")),
      },
      {
        name: "数据资源登记",
        path: "/services2/dataSource",
        meta: { title: "数据资源登记" },
        hidden: false,
        id: "10016",
        component: lazy(() => import("@/views/DataProfessionalServices")),
      },
    ],
  },
  {
    name: "数聚大厅",
    path: "/lobbies",
    meta: { title: "数聚大厅" },
    hidden: false,
    id: "5",
    children: [
      {
        name: "数据需求",
        path: "/lobbies/demand",
        meta: { title: "数据需求" },
        hidden: false,
        id: "6",
        component: lazy(() => import("@/views/DataLobbies/Demand")),
      },
      {
        name: "数据资源",
        path: "/lobbies/dataResource",
        meta: { title: "数据资源" },
        hidden: false,
        id: "10015",
        component: lazy(() => import("@/views/DataLobbies/DataResource")),
      },
    ],
  },
  {
    name: "生态服务",
    path: "/services",
    meta: { title: "生态服务" },
    hidden: false,
    id: "7",
    // component: lazy(() => import("@/views/DataProfessionalServices")),
    children: [
      {
        name: "数商生态",
        path: "/services/dataBusinessEco",
        meta: { title: "数商生态" },
        hidden: false,
        id: "10",
        component: lazy(() => import("@/views/DataProfessionalServices")),
      },
      {
        name: "人才培训",
        path: "/services/TalentTrain",
        meta: { title: "人才培训" },
        hidden: false,
        id: "51",
        component: lazy(() => import("@/views/TalentTrain")),
      },
      {
        name: "培训案例详情",
        path: "/services/DemoDetail",
        meta: { title: "人才培训" },
        hidden: true,
        id: "18",
        component: lazy(() => import("@/views/DemoDetail")),
      },
      {
        name: "人才培训详情",
        path: "/services/TrainDetail",
        meta: { title: "人才培训" },
        hidden: true,
        id: "18",
        component: lazy(() => import("@/views/TrainDetail")),
      },
    ],
  },
  {
    name: "数据要素创新工场",
    path: "/workshop",
    meta: { title: "数据要素创新工场" },
    hidden: false,
    id: "11",
    component: lazy(() => import("@/views/DataElementWorkshop")),
  },
  {
    name: "活动专区",
    path: "/zone",
    meta: { title: "活动专区" },
    hidden: false,
    id: "12",
    children: [
      // {
      //   name: "人才培训",
      //   path: "/zone/talentTraining",
      //   meta: { title: "人才培训" },
      //   component: lazy(() => import("@/views/EventZone/TalentTraining")),
      // },
      ...(process.env.REACT_APP_HIDE_MATCH === "true"
        ? []
        : [
            {
              name: "创新大赛",
              path: "/zone/innovateMatch",
              meta: { title: "创新大赛" },
              hidden: false,
              id: "31",
              component: lazy(() => import("@/views/EventZone/InnovateMatch")),
            },
            {
              name: "创新大赛",
              path: "/zone/innovateMatch/detail",
              meta: { title: "创新大赛详情" },
              hidden: true,
              id: "19",
              component: lazy(
                () => import("@/views/EventZone/InnovateMatch/JoinMatch")
              ),
            },
          ]),
      {
        name: "生态活动",
        path: "/zone/offlinesalons",
        meta: { title: "生态活动" },
        hidden: false,
        id: "13",
        component: lazy(() => import("@/views/EventZone/OfflineSalons")),
      },
    ],
  },
  {
    name: "新闻资讯",
    path: "/news",
    meta: { title: "新闻资讯" },
    hidden: false,
    id: "14",
    component: lazy(() => import("@/views/News")),
  },
  {
    name: "关于我们",
    path: "/about",
    meta: { title: "关于我们" },
    hidden: false,
    id: "15",
    children: [
      {
        name: "帮助支持",
        path: "/about/help",
        meta: { title: "帮助支持" },
        hidden: false,
        id: "16",
        component: lazy(() => import("@/views/About/Help")),
      },
      // {
      //   name: "业务指南",
      //   path: "/about/guide",
      //   meta: { title: "业务指南" },
      //   component: lazy(() => import("@/views/About/Guide")),
      // },
      {
        name: "蓉数公园",
        path: "/about/rongshuPark",
        meta: { title: "蓉数公园" },
        hidden: false,
        id: "17",
        component: lazy(() => import("@/views/About/RongshuPark")),
      },
    ],
  },
  //没有父级菜单的数商生态
  {
    name: "数商生态",
    path: "/dataBusinessEco",
    meta: { title: "数商生态" },
    hidden: false,
    id: "30",
    component: lazy(() => import("@/views/DataProfessionalServices")),
  },
];

export interface DcMenuInterface {
  path: string;
  type?: "url" | "page";
  meta?: { title: string; auths?: number[] };
  haveHeader?: boolean;
  children?: DcMenuInterface[];
  icon?: any;
  key?: string;
}

export const BackendMenuList: DcMenuInterface[] = [
  {
    icon: "notice",
    key: "424",
    path: "/backend/workplat",
    meta: {
      title: "工作台",
      auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
    },
  },
  {
    icon: "userInfo-icon",
    key: "425",
    path: "",
    meta: { title: "账号管理" },
    children: [
      {
        key: "426",
        path: "/backend/userInfo",
        meta: {
          title: "基本信息",
          auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
        },
      },
      {
        key: "427",
        path: "/backend/admissionCert",
        meta: {
          title: "准入认证",
          auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
        },
      },
      {
        key: "4251",
        path: "/backend/accesskey",
        meta: { title: "Accesskey管理", auths: [PartnerTypeEnums.数据使用方] },
      },
      {
        key: "4252",
        path: "/backend/writeList",
        meta: { title: "白名单设置", auths: [PartnerTypeEnums.数据使用方] },
      },
      {
        key: "4253",
        path: "/backend/accountMgt",
        meta: { title: "主子账号管理", auths: [PartnerTypeEnums.数据使用方] },
      },
    ],
  },
  {
    icon: "data-demand",
    key: "430",
    path: "",
    meta: { title: "数聚大厅" },
    children: [
      {
        key: "431",
        path: "/backend/dataDemand",
        meta: {
          title: "数据需求",
          auths: [PartnerTypeEnums.数据使用方],
        },
      },
      {
        key: "432",
        path: "/backend/dataResource",
        meta: {
          title: "数据资源",
          auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
        },
      },
    ],
  },
  {
    icon: "workflow",
    key: "440",
    path: "",
    meta: { title: "产品中心" },
    children: [
      {
        key: "4230",
        path: "/backend/productCenter/productApplication",
        meta: {
          title: "产品申请",
          auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
        },
      },
      {
        key: "4231",
        path: "/backend/productCenter/purchasedProduct",
        meta: { title: "已购产品", auths: [PartnerTypeEnums.数据使用方] },
      },
      {
        key: "4232",
        path: "/backend/productCenter/apiTryout",
        meta: { title: "API在线试用", auths: [PartnerTypeEnums.数据使用方] },
      },
      {
        key: "441",
        path: "/backend/productCenter/authTask",
        meta: { title: "授权抽查任务", auths: [PartnerTypeEnums.数据使用方] },
      },
    ],
  },
  {
    icon: "service-support",
    key: "428",
    path: "",
    meta: { title: "服务支持" },
    children: [
      {
        key: "4281",
        path: "/backend/ServiceSupport/Issue",
        meta: {
          title: "问题反馈",
          auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
        },
      },
      {
        key: "429",
        path: "/backend/ServiceSupport/ProblemFeedback",
        meta: {
          title: "合作咨询",
          auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
        },
      },
    ],
  },
  {
    icon: "st-service",
    key: "438",
    path: "",
    meta: { title: "生态服务" },
    children: [
      {
        key: "4381",
        path: "/backend/TalentTrainList",
        meta: {
          title: "人才培训",
          auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
        },
      },
    ],
  },
  {
    icon: "activity-management",
    key: "4320",
    path: "",
    meta: { title: "活动管理" },
    children: [
      ...(process.env.REACT_APP_HIDE_MATCH === "true"
        ? []
        : [
            {
              key: "434",
              path: "/backend/activitiesManagement/innovativeMatch",
              meta: {
                title: "创新大赛",
                auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
              },
            },
          ]),
      {
        key: "433",
        path: "/backend/activitiesManagement/zoneActivity",
        meta: {
          title: "生态活动",
          auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
        },
      },
    ],
  },
  {
    icon: "notice",
    key: "435",
    path: "",
    meta: { title: "消息管理" },
    children: [
      {
        key: "4351",
        path: "/backend/msgCenter",
        meta: {
          title: "消息中心",
          auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
        },
      },
    ],
  },
  {
    icon: "favorite-icon",
    key: "4360",
    path: "/backend/favorites",
    meta: {
      title: "收藏夹",
      auths: [PartnerTypeEnums.潜客, PartnerTypeEnums.数据使用方],
    },
  },
  {
    icon: "flag",
    key: "436",
    path: "",
    meta: { title: "消费中心" },
    children: [
      {
        key: "4361",
        path: "/backend/consumeCenter/accountManage",
        meta: { title: "账户管理", auths: [PartnerTypeEnums.数据使用方] },
      },
      {
        key: "4362",
        path: "/backend/consumeCenter/billManage",
        meta: { title: "账单管理", auths: [PartnerTypeEnums.数据使用方] },
      },
      {
        key: "4221",
        path: "/backend/ContractManage",
        meta: { title: "合同管理", auths: [PartnerTypeEnums.数据使用方] },
      },
      {
        key: "4220",
        path: "/backend/TransactionDetails",
        meta: { title: "流水明细", auths: [PartnerTypeEnums.数据使用方] },
      },
    ],
  },

  {
    icon: "alis",
    key: "437",
    path: "",
    meta: { title: "日志管理" },
    children: [
      {
        key: "4371",
        path: "/backend/productLog",
        meta: { title: "调用日志", auths: [PartnerTypeEnums.数据使用方] },
      },
    ],
  },
];

// 平铺menu数组
const menuListArr: DcRoute[] = [];
treeForEach(menuList, (item) => {
  menuListArr.push({ name: item.name, path: item.path });
});
export { menuListArr };

export function getMenusByAuth(authType: number, baseMenu = BackendMenuList) {
  const arr = JSON.parse(JSON.stringify(baseMenu));
  const curArr: any[] = [];
  for (let i = 0; i < arr.length; i++) {
    if (Number(arr[i]?.children?.length) > 0) {
      arr[i].children = getMenusByAuth(authType, arr[i].children);
      arr[i].children.length > 0 && curArr.push(arr[i]);
    } else if (arr[i].meta?.auths?.includes(authType)) {
      curArr.push(arr[i]);
    }
  }
  return curArr;
}
