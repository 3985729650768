import { getSystemConfig } from "@/service/common";

// 定义轮询函数的句柄，用于停止定时器
let pollingInterval: NodeJS.Timeout | null = null;

/**
 * @des 根据后端返回的参数设置 root 元素样式
 * 比如哀悼模式，某位郭嘉领导die后系统置灰
 */
async function setSystemFilter(): Promise<void> {
  try {
    // 调用后端接口获取数据
    const response = await getSystemConfig();

    // 获取 root 元素
    const rootElement = document.documentElement;

    if (response === "哀悼风格") {
      // 添加灰色滤镜样式
      rootElement.style.filter = "grayscale(100%)";
      rootElement.style.webkitFilter = "grayscale(100%)"; // 兼容 WebKit
    } else {
      rootElement.style.filter = "";
      rootElement.style.webkitFilter = "";
    }
  } catch (error) {
    console.error("Error fetching data from API:", error);
  }
}

/**
 * 启动轮询，每分钟在整点时调用一次接口
 */
export function startPolling(): void {
  // 停止已有轮询
  stopPolling();

  // **立即调用一次接口**
  setSystemFilter();

  // 使用 setInterval 每分钟调用一次
  pollingInterval = setInterval(() => {
    setSystemFilter();
  }, 60 * 1000);
}

/**
 * 停止轮询
 */
export function stopPolling(): void {
  if (pollingInterval) {
    clearInterval(pollingInterval);
    pollingInterval = null;
  }
}
